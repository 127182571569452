import { Injectable, Injector } from "@angular/core";
import { Messaging, getToken, onMessage, isSupported } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  private vapidKey = "BFjfNv-eY5yo64Igx3naBqN1qDSp8LL-R_lHnzoaskIKbB6hoh6UwuXaL7P6PZdrfisOnFASzspBsMw0vmfbdKw";
  private messaging: Messaging | null = null;

  constructor(private injector: Injector) {}

  async initialize() {
    const supported = await isSupported();
    if (supported) {
      this.messaging = this.injector.get(Messaging);
    } else {
      console.warn("Firebase Messaging is not supported in this browser.");
    }
  }

  requestPermission() {
    if (!this.messaging) {
      console.warn("Messaging service is not available.");
      return;
    }

    getToken(this.messaging, { vapidKey: this.vapidKey })
      .then((token) => {
        if (token) {
          console.log("Notification permission granted.");
        } else {
          console.error("No registration token available.");
        }
      })
      .catch((err) => {
        console.error("Unable to get permission to notify.", err);
      });
  }

  receiveMessage() {
    if (!this.messaging) {
      console.warn("Messaging service is not available.");
      return;
    }

    onMessage(this.messaging, (payload) => {
      console.log("Message received: ", payload);
      this.currentMessage.next(JSON.stringify(payload));
    });
  }

  requestToken() {
    if (!this.messaging) {
      console.warn("Messaging service is not available.");
      return Promise.reject("Messaging service is not available.");
    }

    return getToken(this.messaging, { vapidKey: this.vapidKey });
  }
}
